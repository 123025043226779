.bg-Container1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80%;
  width: 100%;

  background-color: #474b54;
  /* top:-4rem; */

  object-fit: cover;
  position: relative;
}
@media (max-width: 600) {
  .bg-Container1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 100%;
    padding: 0px;
    position: relative;
    background-color: #474b54;
    margin-top: 56px;
    object-fit: cover;
    flex-wrap: wrap;
  }
  .box-container1 {
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    text-align: center;
    border-radius: 1.25rem;
    box-shadow: 0rem 0rem 0.8rem rgba(0, 0, 0, 0.7);
    border-style: solid;

    border-color: rgba(254, 254, 254, 0.5);
  }
}
.box-container1 {
  display: flex;

  justify-content: center;
  text-align: center;
  border-radius: 1.25rem;
  box-shadow: 0rem 0rem 0.8rem rgba(0, 0, 0, 0.7);
  border-style: solid;
  /* bottom:-4rem; */
  border-color: rgba(254, 254, 254, 0.5);
}

.button-container {
  position: absolute;
  bottom: -20px;
  left: 50%;
  border-radius: 16px;
  transform: translateX(-50%);
}

.overflow-button {
  background-color: #167bc4 !important;
  font-size: 20px;
  /* color:rgb(255, 255, 255); */
  border-style: hidden;
  border-radius: 16px;
  width: 10rem;
  height: 3rem;
  max-width: 10rem;
  max-height: 3rem;
}

.on-video-card1 {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: rgb(8, 8, 8);

  width: 20rem;
  height: 26rem;
  padding: 0.8rem;
  /* margin:1rem; */
  top: 8rem;
  backdrop-filter: blur(250px);
  background-color: rgba(61, 69, 128, 0.3);
  /* background-color: var(--primary); */
  background-image: radial-gradient(
      circle at 100% 0%,
      hsla(0, 0%, 100%, 0.08) 29.5%,
      hsla(0, 0%, 100%, 0) 30%
    ),
    radial-gradient(
      circle at 100% 0%,
      hsla(0, 0%, 100%, 0.08) 39.5%,
      hsla(0, 0%, 100%, 0) 40%
    ),
    radial-gradient(
      circle at 100% 0%,
      hsla(0, 0%, 100%, 0.08) 49.5%,
      hsla(0, 0%, 100%, 0) 50%
    );
  border-radius: 0.5em;
  box-shadow: 0 0 0 hsl(0, 0%, 80%), 0 0 0 hsl(0, 0%, 100%),
    -0.2rem 0 0.75rem 0 hsla(0, 0%, 0%, 0.3);
  color: hsl(0, 0%, 100%);

  /* transform: translate3d(1,1,1); */
}

@media (min-width: 768px) {
  .on-video-card1 {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;

    color: rgb(8, 8, 8);
    /* background-color: rgba(163, 161, 161, 0.8); */
    /* width:100%; */
    /* height:auto; */
    width: 24rem;
    height: 26rem;
    padding: 1rem;
    margin: 1rem;
    top: 10rem;
    /* padding-bottom: 1rem; */
    backdrop-filter: blur(50px);
    background-color: var(--primary);

    background-image: radial-gradient(
        circle at 100% 0%,
        hsla(0, 0%, 100%, 0.08) 29.5%,
        hsla(0, 0%, 100%, 0) 30%
      ),
      radial-gradient(
        circle at 100% 0%,
        hsla(0, 0%, 100%, 0.08) 39.5%,
        hsla(0, 0%, 100%, 0) 40%
      ),
      radial-gradient(
        circle at 100% 0%,
        hsla(0, 0%, 100%, 0.08) 49.5%,
        hsla(0, 0%, 100%, 0) 50%
      );
    border-radius: 0.5em;
    box-shadow: 0 0 0 hsl(0, 0%, 80%), 0 0 0 hsl(0, 0%, 100%),
      -0.2rem 0 0.75rem 0 hsla(0, 0%, 0%, 0.3);
    color: hsl(0, 0%, 98%);
  }
}
.fade-in-button:hover {
  opacity: 0.8;
}
